@import 'reset';
@import 'bootstrap';
@import 'variables';
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$design-galaxy-primary: mat-palette($mat-indigo);
$design-galaxy-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$design-galaxy-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$design-galaxy-theme: mat-light-theme(
  (
    color: (
      primary: $design-galaxy-primary,
      accent: $design-galaxy-accent,
      warn: $design-galaxy-warn
    )
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($design-galaxy-theme);

/* You can add global styles to this file, and also import other style files */

@import url(./assets/fontawesome/css/brands.min.css);

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

//START: Common styles
a {
  text-decoration: none;
  color: inherit;
}
.w-100 {
  width: 100%;
}
.bg-light {
  background: $content-bg-light;
}
.bg-medium {
  background: $content-bg-medium;
}
strong {
  font-weight: 600;
}
.f-2rem {
  font-size: 2rem !important;
}
h4.header-title {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.5px;
  margin-bottom: 20px;
}

//END: Common styles

h2 {
  &.decorative-underline {
    position: relative;
    display: inline-block;
    padding-bottom: 5px;
    font-size: 2rem;
    color: $brand-primary;
  }
  &.decorative-underline:before {
    display: block;
    content: '';
    width: 8px;
    height: 8px;
    background-color: $gold-crayola;
    position: relative;
    transform: rotate(45deg) translateX(-50%);
    left: 50%;
    top: 10px;
  }
  &.decorative-underline:after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 3px;
    background: linear-gradient(
      to right,
      $brand-primary 0%,
      $gold-crayola 40%,
      rgba(0, 0, 0, 0) 40%,
      rgba(0, 0, 0, 0) 63%,
      $gold-crayola 63%,
      $brand-primary 100%
    );
  }
  &.decorative-underline:before,
  &.decorative-underline:after {
    position: absolute;
    top: 100%;
  }
  // .line {
  //   position: relative;
  //   margin-bottom: 2rem;
  //   margin-top: 2rem;
  // }
  // .line:before {
  //   display: block;
  //   content: '';
  //   width: 12px;
  //   height: 12px;
  //   background-color: #dfceb9;
  //   position: relative;
  //   transform: rotate(45deg) translateX(-50%);
  //   left: 50%;
  //   top: 10px;
  // }
  // .line:after {
  //   content: '';
  //   position: absolute;
  //   left: 50%;
  //   transform: translateX(-50%);
  //   width: 100%;
  //   max-width: 780px;
  //   height: 3px;
  //   background: linear-gradient(
  //     to right,
  //     #dfceb9 0%,
  //     #dfceb9 45%,
  //     rgba(0, 0, 0, 0) 45%,
  //     rgba(0, 0, 0, 0) 55%,
  //     #dfceb9 55%,
  //     #dfceb9 100%
  //   );
  // }
  &.text-bold {
    font-weight: bold;
  }
}

// START: Button style
button.app-primary {
  font-size: 14px;
  background: $brand-primary;
  color: #fff;
  cursor: pointer;
  border: 3px solid $brand-primary;
  border-radius: 4px;
  padding: 8px 16px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  &:hover {
    color: $brand-primary;
  }
  &::after {
    content: '';
    background: #fff;
    position: absolute;
    z-index: -1;
    padding: 8px 16px;
    display: block;
    transition: all 0.2s ease;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transform: scale(0, 0);
  }
  &:hover::after {
    transform: scale(1, 1);
    transition: all 0.2s ease-out;
  }
}
// END: Button style

// START: Card style
.item-wrapper {
  $card-border-radius: 16px;
  gap: 30px;
  max-width: 90%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .item-box {
    min-width: 300px;
    max-width: 360px;
    // min-height: 300px;
    max-height: 360px;
    border-radius: $card-border-radius;
    background: #fff;
    box-shadow: 2px 2px 7px $gold-crayola;
    transition: 0.3s;
    &:hover {
      transform: scale(1.05);
    }
    .image-wrapper {
      width: 100%;
      flex: 1;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        border-top-right-radius: $card-border-radius;
        border-top-left-radius: $card-border-radius;
      }
    }
    .text h4 {
      font-size: 20px;
      font-weight: 500;
      margin: 10px 0;
    }
  }
}

@media (min-width: 1024px) {
  .item-wrapper {
    max-width: 80%;
    gap: 40px;
    flex-direction: row !important;
  }
}

// END: Card style

// START: Section style
.section-wrapper {
  min-height: $section-shrinked-header-nav-height;
  display: flex;
  justify-content: center;
}
// END: Section style
