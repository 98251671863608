
/*** Brand colors ***/
$purple-navy: #4e598c;
$white: #ffffff;
$gold-crayola: #f9c784;
$yellow-orange: #fcaf58;
$mango-tango: #ff8c42;
$brand-primary: #ea5922;
$content-bg-light: #faf6f0;
$content-bg-medium: #fff0db;
/*** Brand colors ***/


/*** Header and Nav variables ***/
$header-bg: #fff;
$nav-bg: #fff;
$nav-menubar-lines-accent: #fff;
$nav-link-hover-bg: lighten($gold-crayola, 20%);

$header-nav-height: 80px;
$header-nav-shrinked-height: 60px;
$section-height: calc(100vh - 80px);
$section-shrinked-header-nav-height: calc(100vh - 60px);
/*** Header and Nav variables ***/